<template>
  <div class="pop_container">
    <div class="title_area">
      <a @click="closeModal" class="close">X</a>
    </div>
    <div class="body_area">
        <p class="signature-img-center"><img :src="image_src"></p>
    </div>
  </div>
</template>
<script>
export default {
    name:"popImage",
    props:["image_src"],
    methods:{
        closeModal(){
            this.$emit("modalClose");
        }
    }
};
</script>
<style lang="scss">
.pop_container {
  height: auto;
  .title_area {
    a.close {
      width: 20px;
      height: 20px;
      float: right;
      color: #fff;
      display: block;
      margin-right: 20px;
    }
  }
}
.form_section_generate_slip input[type="text"] {
  width: 100%;
}
.pop_comment_div {
  padding: 20px;
  margin: 20px 20px 0 20px;
  height:  250px;
  overflow-y:auto;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
}
.write_comment_textarea {
  width: calc(100% - 40px);
  box-sizing: border-box;
  padding: 20px;
  margin: 20px 20px 0 20px;
  background: #fff;
  height: 100px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 10px;
}
.submit_comment {
  background: #004a7c;
  color: #fff;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
  margin: 20px auto 20px 20px;
  border-radius: 10px;
}
.comment_container{
  padding:10px;
  width:100%;
  border:1px solid #ddd;
  margin-bottom:12px;
}
.comment_sub_container{
  display:flex;
  width:100;
  justify-content:space-between;
  margin-bottom:10px;
}
.comment_content{
  font-size:14px;
  width:100%;
  word-wrap: break-word;
}
.close_icon{
  cursor:pointer;
  font-size:20px !important;
  float:right;
}
.signature-img-center{
    text-align:center;
    img{
        width:auto;
        height:400px;
    }
}
</style>